import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import moment from "moment"

import UrlService from "../services/global/urlService"
import AbstractMedia from "../components/media/AbstractMedia"
import { ATInternetTagService } from './../services/global/ATInternetService';

class ActuListTemplate extends React.Component {
  data
  currentPage
  numPages
  prevPage
  nextPage
  isFirst
  isLast
  currentP
  actuPerPage
  media = {}

  async componentDidMount() {
    ATInternetTagService.sendPage({page: 'actualites',page_chapter1:'actualites'})
  }

  render() {
    this.data = this.props.pageContext.actuRange
    this.currentPage = this.props.pageContext
    this.isFirst = this.currentPage.currentPage === 1
    this.isLast = this.currentPage.currentPage === this.currentPage.numPages
    this.prevPage =
      this.currentPage.currentPage - 1 === 1
        ? "/"
        : (this.currentPage.currentPage - 1).toString()
    this.nextPage = (this.currentPage.currentPage + 1).toString()
    this.media = this.props.pageContext.media
    
    const metaRobots = [
      {
        name: "robots",
        content: "noindex",
      },]

    return (
      <Layout
        titleSeo="Actualités santé et prévoyance"
        description={
          "Retrouvez toute l'actualité de la Mutuelle MGC pour vous tenir au courant des nouveautés en matière de complémentaire santé et pévoyance."
        }
        meta={metaRobots}
      >
        <section className="pageHeader actu-header">
          <div className="pageHeaderContainer">
            <nav>
              <ul className="breadcrumb">
                <li className="breadcrumbItem">
                  <a
                    href="/"
                    className="breadcrumbItemLink breadcrumbItemLinkHome"
                  >
                    <i className="icon-mgc" />
                  </a>
                </li>
                <li className="breadcrumbItem">
                  <span className="breadcrumbItemLink ">Actualités</span>
                </li>
              </ul>
            </nav>
          </div>
          <div>
            <h1 className="pageHeaderTitle pageHeaderTitle-with-text">
              Actualités
            </h1>
            <p className="pageHeaderSubtitle">
              Retrouvez toute l’actualité de la mutuelle MGC !
            </p>
          </div>
        </section>
        <div className="container">
          <section className="actualites">
            <div id="fb-root" />
            {this.data.map(actu => (
              <div key={actu.id}>
                <div className="row">
                  {actu.media ? (
                    <div className="col-12 col-sm-4">
                      <a>
                        <AbstractMedia
                          abstractMedia={actu.media}
                          media={this.media}
                          className="actualitesArticlesImage img-fluid"
                        />
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-12 col-sm-8">
                    <div className="actualitesArticles">
                      <h4 className="actualitesArticlesTitle">
                        <a
                          href={`/actualite/${UrlService.addSlashToEndOfUrl(
                            actu.url
                          )}`}
                        >
                          {actu.title}
                        </a>
                      </h4>
                      <p className="actualitesArticlesPublication">
                        {actu.description}
                      </p>
                      <em className="actualitesArticlesDate">
                        Publié le{" "}
                        {moment(actu.formattedPublished).format("DD/MM/YYYY")}
                      </em>
                      <a
                        className="actualitesArticlesLink"
                        href={`/actualite/${UrlService.addSlashToEndOfUrl(
                          actu.url
                        )}`}
                        onClick={() => ATInternetTagService.sendNavClick({click:actu.title,chapter_2:'lire_la_suite'})}>
                        {" "}
                        Lire la suite
                      </a>
                    </div>
                  </div>
                </div>
                <hr className="actualitesHr" />
              </div>
            ))}
            <div>
              <ul className="actualitesPagination">
                <li className="actualitesPaginationItem">
                  {!this.isFirst && (
                    <Link to={"/actualites/" + this.prevPage} rel="prev">
                      ←
                    </Link>
                  )}
                </li>
                {Array.from({ length: this.currentPage.numPages }, (_, i) => (
                  <li key={i} className="actualitesPaginationItem">
                    <Link
                      to={`${
                        i === 0 ? "/actualites/" : "/actualites/" + (i + 1)
                      }/`}
                    >
                      {i + 1}
                    </Link>
                  </li>
                ))}
                <li className="actualitesPaginationItem">
                  {!this.isLast && (
                    <Link to={"/actualites/" + this.nextPage} rel="next">
                      →
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export default ActuListTemplate
